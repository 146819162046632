import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { useIntl } from 'gatsby-plugin-intl'

const useStyles = (isTitleHanging = false) =>
  makeStyles((theme) => ({
    root: {
      fontFamily: `'Cormorant Garamond', serif`,
      textAlign: 'center',
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.between('xs', 'md')]: {
        minHeight: '60vh',
      },
    },
    title: {
      margin: '0 auto',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(6),
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: '3rem',
      textAlign: 'center',
      textTransform: 'uppercase',
      lineHeight: '.9',
      [theme.breakpoints.between('xs', 'sm')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        fontSize: theme.typography.pxToRem(20),
      },
    },
    title2: {
      [theme.breakpoints.up('md')]: {
        marginLeft: '174px',
      },
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: '1.5rem',
      lineHeight: 1.1,
      textAlign: 'left',
      fontWeight: 400,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.between('xs', 'sm')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        fontSize: theme.typography.pxToRem(17),
      },
    },
    enum1: {
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: '1rem',
      lineHeight: 0.9,
      textAlign: 'left',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.1),
      marginLeft: theme.spacing(2),
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    enum2: {
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: '1rem',
      lineHeight: 0.9,
      textAlign: 'left',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(4),
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    description: {
      [theme.breakpoints.up('md')]: {
        marginLeft: '174px',
      },
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: '1rem',
      lineHeight: 1.1,
      textAlign: 'left',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginRight:'255px',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(0.8),
        fontSize: theme.typography.pxToRem(12),
      },
    },
    descriptionBold: {
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: '1.1rem',
      lineHeight: 1.1,
      textAlign: 'left',
      fontWeight: 600,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1.6),
        marginBottom: theme.spacing(0.9),
        fontSize: theme.typography.pxToRem(14),
      },
    },
    address: {
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: '1rem',
      lineHeight: 1.2,
      textAlign: 'left',
      marginTop: theme.spacing(0.6),
      marginBottom: theme.spacing(0.4),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    addressBloc: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }))

export const FeesNotice: React.FC<any> = () => {
  const intl = useIntl()
  const classes = useStyles(false)()
  return (
    <Grid md={12} container justifyContent="center" className={classes.root}>
  
        <Grid item sm={12} md={12} className={classes.title}>
          {intl.formatMessage({ id: 'link.feesTitle' })}
        </Grid>
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'feesNotice.title_art_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'feesNotice.description_1_art_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'feesNotice.description_2_art_1' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'feesNotice.title_art_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'feesNotice.description_1_art_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'feesNotice.description_2_art_2' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'feesNotice.title_art_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'feesNotice.description_1_art_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'feesNotice.description_2_art_3' })}
        </Grid>

    </Grid>
  )
}

export default FeesNotice
